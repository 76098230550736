<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div style="display: flex; gap: 16px; align-items: center; margin-bottom: 24px; flex-wrap: wrap; row-gap: 8px;">
        <h4 style="margin: 0;">Your Services</h4>
        <router-link to="/save-service" class="btn btn-primary">+ Add new service</router-link>
      </div>
      <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
      <div class="d-flex justify-content-start flex-wrap listing-cards-container" v-if="loading === false" >
        <a-card hoverable class="listing-card" v-for="item in items" :key="item.id">
          <template #cover>
            <div class="active-indicator-badge" v-if="item.is_published && item.subscription_id" style="background-color: #17bc17;">Active</div>
            <div class="active-indicator-badge" v-else-if="item.is_draft" style="background-color: #7e7e7e;">Draft</div>
            <div class="active-indicator-badge" v-else style="background-color: #ff982e;">Inactive</div>
            <img src="../assets/img/placeholder_logo.png" v-if="item.images == null || item.images.length === 0" style="object-fit: contain; height: 200px; border: none; padding: 16px;" />
            <img :src="item.images[0]" v-if="item.images && item.images.length > 0" style="object-fit: cover; height: 200px;" />
          </template>
          <template class="ant-card-actions" #actions>
            <a href="javascript:;" @click="openService(item.id)">Edit Service</a>
            <router-link v-if="item.is_published && item.subscription_id != null" :to="{path: '/my-payment-methods'}">Update Payment Method</router-link>
            <router-link v-if="item.is_published && item.subscription_id != null" :to="{name: 'change-service-plan', params: {id: item.id}}">Change Plan</router-link>
            <router-link v-if="!item.is_published && !item.is_draft" :to="{path: '/plans', query: {next_page: 'save-service', next_id: item.id}}">Make Active</router-link>
            <a href="javascript:;" @click="promptConfirmDelete(item.id)">Delete Service</a>
          </template>
          <a-card-meta :title="item.business_name" :description="(item.subscription_id && item.list_type) ? this.plans[item.list_type].name : null"><template #description></template></a-card-meta>
        </a-card>
      </div>
      <div v-if="items.length === 0 && loading === false">
        You do not have any listings.
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import {DeleteOutlined, SettingOutlined, EditOutlined} from '@ant-design/icons-vue';
import ACard from 'ant-design-vue/lib/card';
import ASpin from 'ant-design-vue/lib/spin';
import APopConfirm from "ant-design-vue/lib/popconfirm";
import APopover from 'ant-design-vue/lib/popover';
import AModal from 'ant-design-vue/lib/modal';
import AButton from 'ant-design-vue/lib/button';
import 'ant-design-vue/lib/card/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import 'ant-design-vue/lib/popover/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import 'ant-design-vue/lib/modal/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';

import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import Swal from "sweetalert2";
import plans from '@/constants/plans';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ACard,
    ASpin,
    APopConfirm,
    AModal,
    AButton,
    'a-card-meta': ACard.Meta,
    EditOutlined,
    DeleteOutlined,
    SettingOutlined,
    APopover
  },
  data() {
    return {
      loading: false,
      items: [],
      plans
    }
  },
  async mounted() {
    document.title = 'My Services - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      if (this.$route.query.upgrade_completed) {
        Swal.fire({
          title: 'Completed',
          text: 'Plan is upgraded!'
        });
      }
      this.loading = true;
      try {
        let response = await axios.get('/api/my-services');
        this.items = response.data.items.sort((a, b) => {
          if (a.is_published && a.subscription_id) {
            return -1;
          } else if (b.is_published && b.subscription_id) {
            return 1;
          }
          if (a.is_draft) {
            return -1;
          } else if (b.is_draft) {
            return 1;
          }
          return 1;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    openService(id) {
      this.$router.push({path: '/save-service', query: {id: id}});
    },
    changeServicePlan(id) {
      this.$router.push({name: 'change-service-plan', params: { id }});
    },
    promptConfirmDelete(id, reason) {
      const modal = AModal.confirm({
        title: 'Are you sure you want to delete this service?',
        maskClosable: true,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          this.deleteListing(id, reason);
          modal.destroy();
        }
      });
    },
    async deleteListing(id) {
      this.loading = true;
      try {
        await axios.delete(`/api/services/${id}`);
        await this.loadItems();
        AModal.info({
          title: 'Your listing has been deleted.'
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep(.ant-card) {
    display: flex;
    flex-direction: column;
  }

  ::v-deep(.ant-card-actions) {
    flex: 1;
    display: flex;
  }

  ::v-deep(.ant-card-actions li) {
    height: auto;
    align-self: center;
  }

  ::v-deep(.ant-card-actions li span) {
    width: 75%;
    margin: auto;
  }

  ::v-deep(.ant-card-actions > li > span > a:not(.ant-btn)) {
    color: #3a3a3a;
  }

  @media (max-width: 480px) {
    ::v-deep(.ant-card-actions) {
      flex-direction: column;
    }

    ::v-deep(.ant-card-actions > li) {
      margin: 8px 0;
      width: 100% !important;
    }

    ::v-deep(.ant-card-actions li span) {
      width: 100%;
      margin: 0;
    }
  }

  .listing-cards-container {
    gap: 10px;
  }

  .listing-card {
    width: calc(33% - 10px * 2 / 3);
  }

  .active-indicator-badge {
    position: absolute;
    width: fit-content;
    padding: 4px 8px;
    color: white;
    border-radius: 4px;
    left: 24px;
    top: 16px;
  }

  @media screen and (max-width: 480px) {
    .listing-card {
      width: calc(50% - 10px / 2);
    }
  }
</style>
